body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container {
  min-height: 100vh;
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: relative;
}

.container .accent-div {
  width: 35%;
  height: 100%;
  background-color: #ffb664;
  position: absolute;
  top: 0;
  right: 0;
}

.navigation {
  text-transform: uppercase;
  grid-template-columns: 1fr 3fr 1fr 1fr;
  justify-content: space-between;
  font-family: Satoshi, sans-serif;
  font-size: 14px;
  font-weight: 700;
  display: grid;
  position: relative;
}

.navigation .logo {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
}

.navigation .logo .circle {
  width: 15px;
  height: 15px;
  background-color: #010001;
  border-radius: 50%;
}

.navigation .nav {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  display: flex;
}

.navigation .nav li {
  cursor: pointer;
  list-style-type: none;
}

.navigation .nav li:after {
  content: "";
  width: 0%;
  height: 1.5px;
  background-color: #010001;
  transition: width .2s linear;
  display: block;
  position: relative;
}

.navigation .nav li.active:after, .navigation .nav li:hover:after {
  width: 100%;
}

.navigation .menu {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.navigation .menu button {
  background-color: #0000;
  border: none;
}

.main-section {
  grid-template-columns: 1fr 1fr;
  padding-top: 2rem;
  padding-bottom: 1rem;
  display: grid;
  position: relative;
}

.main-section img {
  max-width: 100%;
  max-height: 100%;
}

.main-section .designer-info {
  flex-direction: column;
  justify-content: center;
  padding-left: 3rem;
  display: flex;
}

.main-section .designer-info h5 {
  text-transform: uppercase;
  margin: 0;
  font-family: Satoshi, sans-serif;
}

.main-section .designer-info h1 {
  color: #010001;
  margin: 0;
  font-family: Melodrama, serif;
  font-size: 6rem;
}

.main-section .designer-info .designer-avatar {
  flex-direction: row;
  gap: 20px;
  display: flex;
}

.main-section .designer-info .designer-avatar .avatar-container {
  width: 200px;
}

.main-section .designer-info .designer-avatar .location {
  flex-direction: column;
  gap: 5px;
  display: flex;
}

.main-section .designer-info .designer-avatar h5:nth-child(2) {
  color: #969596;
  font-size: 12px;
}

.main-section .designer-info .designer-avatar h5:nth-child(1) {
  color: #010001;
  font-size: 14px;
}

.main-section .furniture-carousel {
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  display: flex;
}

.main-section .furniture-carousel .picture-container {
  width: 550px;
}

.main-section .furniture-carousel .carousel-counter {
  color: #010001;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-family: Satoshi;
  font-size: 13px;
  font-weight: 700;
  display: flex;
}

.main-section .furniture-carousel .carousel-counter .hr {
  width: 100px;
  height: 1px;
  background-color: #010001;
  display: block;
}

/*# sourceMappingURL=index.50198113.css.map */
