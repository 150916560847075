$accent: #FFB664;
$primary-grey: #FFFFFF;
$black: #010001;
$dark-grey: #969596;


//fonts 
$serif: 'Melodrama';
$sans: 'Satoshi';

//primary colors
$primary-2: #CECDCE;
$primary-3: #CFCECF;
$primary-4: #B5B8C3;

//accent colors 
$accent-2: #D9C68E;
$accent-3: #BBD49B;
$accent-4: #E0E5E7;
