@import "variables.scss";

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
    background-color: $primary-grey;
    position: relative;
    min-height: 100vh;
    width: 100%;
    height: 100%;

    .accent-div {
        position: absolute;
        // z-index: 0;
        width: 35%;
        height: 100%;
        top: 0px;
        right: 0px;
        background-color: $accent;
    }
}

.navigation {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr 1fr;
    justify-content: space-between;
    font-family: $sans, sans-serif;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;

    .logo {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 8px;

        .circle {
            width: 15px;
            height: 15px;
            background-color: $black;
            border-radius: 50%;
        }
    }

    .nav {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        //justify-content: space-around;
        gap: 5rem;

        li {
            list-style-type: none;
            cursor: pointer;

            &::after {
                content: "";
                position: relative;
                display: block;
                width: 0%;
                height: 1.5px;
                background-color: $black;
                transition: width 0.2s linear;
            }

            &.active {
                &::after {
                    width: 100%;
                }
            }

            &:hover {
                &::after {
                    width: 100%;
                }
            }
        }
    }

    .menu {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        button {
            background-color: transparent;
            border: none;
        }
    }
}

.main-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    padding-top: 2rem;
    padding-bottom: 1rem;
    img {
        max-width: 100%;
        max-height: 100%;
    }
    
    .designer-info {
        display: flex;
        flex-direction: column;
        padding-left: 3rem;
        justify-content: center;

        h5 {
            font-family: $sans, sans-serif;
            text-transform: uppercase;
            margin: 0;
        }

        h1 {
            font-family: $serif, serif;
            font-size: 6rem;
            margin: 0;
            color: $black;
        }

        .designer-avatar {
            display: flex;
            flex-direction: row;
            gap: 20px;
            .avatar-container {
                width: 200px;
                // img {
                //     filter: grayscale(70%);
                // }
            }
            .location {
                display: flex;
                flex-direction: column;
                gap: 5px;
            }
            h5:nth-child(2) {
                color: $dark-grey;
                font-size: 12px;
            }
            h5:nth-child(1) {
                color: $black;
                font-size: 14px;
            }
        }
    }

    .furniture-carousel {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;

        .picture-container {
            width: 550px;
        }

        .carousel-counter {
            font-family: $sans;
            font-weight: 700;
            font-size: 13px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            color: $black;

            .hr {
                width: 100px;
                height: 1px;
                background-color: $black;
                display: block;
            }
        }
    }
}
